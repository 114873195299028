import "imagesloaded";
import "readmore-js";

$(function () {
    var $grid = $(".block-grid").imagesLoaded(function () {
        $(".block-brick", $grid).equalHeight();
    });

    var $body = $("body"),
        $navmenu = $("#sidebarNavigation"),
        $mypages = $("#sidebarMypages"),
        $navmenubutton = $("[data-target='#sidebarNavigation']"),
        $mypagebutton = $("[data-target='#sidebarMypages']"),
        $hidingNavigation = $("#hidingNavigation"),
        $pagewrapper = $("#page-wrapper"),
        $menuItems = $("ul.navmenu-nav li a", $navmenu),
        shouldTrackNextClick = false;

    $("img", $body).each(function () {
        var $parent = $(this).parent();
        if ($parent.get(0).tagName !== "A") return;
        $parent.addClass("arrow-hidden");
    });

    //Not sure if this is still used
    function iPadPortrait() {
        $("div.row", $pagewrapper).each(function () {
            $("div.block:not(.block-imagewithoverlay):not(.block-footer):not(.block-game):not(.titleleadandtextblock):not(.block-header)", $(this)).equalHeight();
        });
    }

    if ($.browser.mobile || $.browser.tablet) {
        $(window).on("resize", iPadPortrait).on("load", iPadPortrait);
    }

    $(".height-4x").each(function () {
        var $self = $(this);
        if ($self.siblings(".height-2x").length <= 1) return;
        $self.height($self.height() + 5);
    });

    $(".navbar .login, .block-xs-login .login, .navmenu-superheader .login, .gamepausepage .login").click(function (e) {
        e.preventDefault();
        Utils.Authenticate();
    });

    $(".navbar .createaccount, .block-xs-login .createaccount, .navmenu-superheader .createaccount").click(function (e) {
        e.preventDefault();
        Utils.Authenticate(null, "create");
    });

    $navmenu.on("show.bs.offcanvas", function () {
        shouldTrackNextClick = true;
        if (window.dataLayer) {
            window.dataLayer.push({
                "event": "FsEvent",
                "eventCategory": "Menu",
                "eventAction": "ShowMenu"
            });
        }
    }).on("hide.bs.offcanvas", function () {
        shouldTrackNextClick = false;
    });

    $menuItems.click(function (e) {
        var $self = $(this);
        if (!shouldTrackNextClick) return;
        e.preventDefault();

        if (window.dataLayer) {
            window.dataLayer.push({
                "event": "FsEvent",
                "eventCategory": "Menu",
                "eventAction": "ClickMenuItem",
                "eventLabel": $self.text(),
                "eventValue": ($menuItems.index($self) + 1).toString(),
                "eventCallback": function () {
                    window.location.href = $self.attr("href");
                }
            });
        }

        setTimeout(function () {
            window.location.href = $self.attr("href");
        }, 1000);
    });

    var $imageWithOverlays = $("body .imagewithoverlayblock .block-content");
    $imageWithOverlays.off("click").click(function (e) {
        e.preventDefault();
        var $self = $(this), $link = $("a", $self), $tab = $self.data("widget-tab"), track = false;

        if (window.dataLayer && $link.length === 1) {
            window.dataLayer.push({
                "event": "FsEvent",
                "eventCategory": "Promotion",
                "eventAction": "Click",
                "eventLabel": $link.text() + "::" + $("span.h3", $self).text(),
                "eventValue": $imageWithOverlays.index($self) + 1,
                "eventCallback": function () {
                    track = true;
                    if ($tab) {
                        window.open($link.attr("href"));
                    } else {
                        window.location.href = $link.attr("href");
                    }

                }
            });
        }

        setTimeout(function () {
            if (track) return;

            if ($tab) {
                window.open($link.attr("href"));
            } else {
                window.location.href = $link.attr("href");
            }
        }, 1000);
    });

    $navmenubutton.click(function () {
        if ($mypages.hasClass("canvas-slid"))
            $mypages.offcanvas("hide");
        else
            $hidingNavigation.slideDown(10);
    });

    $mypagebutton.click(function () {
        if ($navmenu.hasClass("canvas-slid"))
            $navmenu.offcanvas("hide");
        else
            $hidingNavigation.slideDown(10);
    });

    $navmenu.on("hidden.bs.offcanvas", function () {
        $hidingNavigation.slideUp(10);
    });

    $mypages.on("hidden.bs.offcanvas", function () {
        $hidingNavigation.slideUp(10);
    });

    $(".readmore-wrapper").readmore({
        collapsedHeight: 230,
        embedCSS: false,
        moreLink: '<div class="text-center"><a class="btn btn-default" href="#">Visa mer</a></div>',
        lessLink: '<div class="text-center"><a class="btn btn-default" href="#">Visa mindre</a></div>',
        afterToggle: function (trigger, element, expanded) {
            if (!expanded) { // The "Close" link was clicked
                $("html, body").animate({ scrollTop: 0 }, { duration: 500 });
            }
        }
    });

    $(".drawlist .btn").click(function () {
        var url = $('select', $(this).parent()).val();
        if (url !== "") {
            window.open(url);
        }
        return false;
    });

    if (Utils.UserIsNyxActive()) {
        setTimeout(function () {
            Utils.HasSetTurnoverLimit().then(function (response) {
                if (!response.HasSetLimit) {
                    ModalHandler.SetTurnoverLimit();
                }
            });
        }, 600);
    }
});