$.widget("custom.ticketcheckerform", {
  options: {
      serialid: "",
      lotteryid: ""
  },
  _create: function() {
      var $widget = this,
          $form = $widget.element;

      if ($widget.options.serialid === "" || $widget.options.lotteryid === "") return;

      var $serialNumber = $("#" + $widget.options.serialid, $form),
          $lotteryNumber = $("#" + $widget.options.lotteryid, $form),
          $trailingNumber = $("div.input-skrap-slutsiffra span", $form),
          $submit = $("button[type='submit']", $form).prop("disabled", true).attr("disabled", "disabled"),
          validKeyCodes = [8, 13, 37, 38, 39, 40, 46];

    function checkSerial($element, e, keyCode) {
      if ($element.val().length === parseInt($element.attr("maxlength"))) {
        if (typeof (e) !== "undefined" && e != null && validKeyCodes.indexOf(keyCode) === -1) e.preventDefault(); // causes issues with firefox
      } else if ($element.val().length === 0) {
        $lotteryNumber.val("");
        $trailingNumber.text("X");
        $submit.prop("disabled", true).attr("disabled", "disabled");
      }
    }

    function checkLottery($element, e, keyCode) {
      var trailingValue = $element.val();
      if (trailingValue.length === parseInt($element.attr("maxlength"))) {
        $submit.prop("disabled", false).removeAttr("disabled");
        $trailingNumber.text(trailingValue.substring(trailingValue.length - 1, trailingValue.length));
        if (typeof (e) !== "undefined" && e != null && validKeyCodes.indexOf(keyCode) === -1) e.preventDefault(); // causes issues with firefox
        if (typeof (keyCode) === "number" && keyCode === 13) $submit.click();
      } else {
        $trailingNumber.text("X");
        $submit.prop("disabled", true).attr("disabled", "disabled");
      }
    }

    checkSerial($serialNumber, null, 0);
    checkLottery($lotteryNumber, null, 0);

    $serialNumber.on("keyup keypress", function (e) {
      var keyCode = (e.keyCode || e.which);
      checkSerial($(this), e, keyCode);
    });

    $lotteryNumber.on("keyup keypress", function(e) {
      var keyCode = (e.keyCode || e.which);
      checkLottery($(this), e, keyCode);
    });

    $lotteryNumber.on("focus", function(e) {
      if ($serialNumber.val().length < parseInt($serialNumber.attr("maxlength"))) {
        $serialNumber.focus();
        e.preventDefault();
        return;
      }
    });
  }
});