import { Spinner } from "spin.js";

$.widget("custom.extraticketselector", {
    options: {
        bankidvisibledata: "",
        balance: "",
        ticketname: "",
        ticketprice: "",
        ssn: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            options = this.options,
            $ticketRows = $(".ticket-row"),
            summation = $(".summation"),
            termsCheckBox = $("#PolicyCheckBox"),
            buyButton = $();

        $ticketRows.each(function () {
            //Check if user can add or remove tickets, add classes to buttons

            var addTicket = $(this).find(".add-ticket");
            var removeTicket = $(this).find(".remove-ticket");
            var ticketCounter = $(this).find(".amount-of-tickets");
            var ticketInput = $(this).find("#ticketsInput");
            ticketInput.val(ticketCounter.text());

            addTicket.click(function () {
                var sum = parseInt(ticketInput.val()) + 1;
                if (!addTicket.hasClass("disabled")) {
                    ticketInput.val(sum)
                    ticketCounter.text(sum);
                    updateSum(sum);

                    if (sum == 9) {
                        addTicket.addClass("disabled");
                    }
                    if (sum > 1) {
                        removeTicket.removeClass("disabled");
                    }
                }
            });

            removeTicket.click(function () {
                var sum = parseInt(ticketInput.val()) - 1;
                if (sum > 0 && !removeTicket.hasClass("disabled")) {
                    ticketInput.val(sum)
                    ticketCounter.text(sum);
                    updateSum(sum);

                    if (sum == 1) {
                        removeTicket.addClass("disabled");
                    }
                    if (sum < 9) {
                        addTicket.removeClass("disabled");
                    }
                }
            });

        
        });

        termsCheckBox.change(function () {
            if ($(this).is(':checked')) {
                $("#confirmButton").attr('disabled', false);
            } else {
                $("#confirmButton").attr('disabled', true);
            }
            
        });

        function updateSum(tickets) {
            var sum = parseInt(tickets) * options.ticketprice;
            summation.text(sum + " kr");
            if (sum + options.ticketprice > options.balance) {
                $(".add-ticket").addClass("disabled");
            }
        }
     
    }
});