import Cookies from "js-cookie";

$.widget("custom.gameclient", {
    options: {
        gameid: null,
        session: null,
        wagerid: null,
        skinname: "",
        gameclientcacheparam: 0,
        firstpurchasecategory: "1"
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        window.onkeydown = function (e) {
            return !(e.keyCode === 32 && e.target === document.body);
        };

        function onOrientationChange() {
            if (!$.browser.mobile && !$.browser.tablet) return;
            $("#page-wrapper .hero").addClass("gamepage");
            $(".block-xs-login").parent().addClass("hide");
            Cookies.remove("mymoneyState");
            $(".navbar-mymoney").slideUp(300, function () {
                $(".btn-mymoney").fadeIn();
            });

            changeTextPosition();
        }

        function changeTextPosition() {
            var $text = $(".nyxChatInputTextArea"),
                $canvas = $(".gameclient canvas");

            if ($text.length === 1 && $canvas.length === 1 && ($.browser.tablet || $.browser.mobile)) {
                var top = $canvas.height() + $canvas.offset().top + ($text.height() / 2),
                    left = $canvas.width() - $canvas.offset().left - $text.width();
                $text.css({ top: top, right: left });
            }
        }

        $(window).on("orientationchange", onOrientationChange).on("resize", onOrientationChange);

        onOrientationChange();

        var wagerid = null, session = null, skinname = "Default", gameid = null;

        if (this.options.wagerid) {
            wagerid = this.options.wagerid;
        } else {
            var param = Utils.GetUrlParamByName("wagerId");
            if (param) wagerid = param;
        }

        if (this.options.session) {
            session = this.options.session;
        } else if ($("body").data("sessionid")) {
            session = $("body").data("sessionid");
        }

        if (this.options.gameid) {
            gameid = this.options.gameid;
        }

        if (this.options.skinname && this.options.skinname !== "") {
            skinname = this.options.skinname;
        }

        var options = {
            operatorAssetRoot: "/globalassets/gameclient/html5/bingo",
            settingsPath: "/globalassets/gameclient/html5/bingo/settings.json",
            skinname: skinname,
            language: "sv",
            culture: "se",
            gameId: gameid,
            session: session,
            skipFastForward: "true",
            overrideWithLocalStorage: true,
            optionalNoCacheLoadParam: $widget.options.gameclientcacheparam !== 0
                ? $widget.options.gameclientcacheparam.toString()
                : "5"
        };

        if (wagerid) {
            options.wagerId = wagerid;
        }

        //window.buildNumber = "1.1.114_54733";
        $.cachedScript("/nyxclient/html5/bingo/nyxclient.min.js?v=" + $widget.options.gameclientcacheparam).done(function (data, textStatus, jqxhr) {
            try {
                var nyxbingoclient = new NYXBINGO.NyxClient(document.getElementById("gamehtml5"));
                window.nyxbingoclient = nyxbingoclient;
                nyxbingoclient.run(options);
                nyxbingoclient.getPortalIntegration().listener(function (status, payload) {
                    switch (status) {
                        case "BINGO_GAME_ON_WAGER":
                            var totalAmount = payload.tickets * payload.price;

                            window.fsFirstPurchase.handleFirstPurchase($widget.options.firstpurchasecategory);

                            Utils.TrackEComPurchaseWithEvent("Onlinespel", payload.wagerid, {
                                transactionId: payload.wagerid,
                                transactionTotal: totalAmount,
                                transactionProducts: [
                                    {
                                        sku: payload.gameid,
                                        name: document.title,
                                        category: "Onlinespel",
                                        price: payload.price,
                                        quantity: payload.tickets
                                    }
                                ]
                            });
                            $(document).trigger("updatemybalance");
                            break;
                        case "BINGO_GAME_WAGER_FAILED":
                        case "GAME_SUMMARY_CLOSED":
                            $(document).trigger("updatemybalance");
                            break;
                        case "GAME_LOADED":
                        case "CLIENT_READY":
                            changeTextPosition();
                            break;
                    }
                });

            } catch (e) {
                console && console.error && console.error(e);
            }
        });
    }
});