$.widget("custom.VerifyInformationButton", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $form = $("form", $element);


        $('#verifyButton', $element).on('click', function () {
            Utils.VerifyMobileAndEmail();
        })

        /*DOCUMENT READY*/
        $(document).ready(function () {

            const urlParams = new URLSearchParams(window.location.search);
            const value = urlParams.get('result');

            //TODO: Lägg till check för att se att användaren är authorized.
            if (value != null && value == 'success') {
                $('.VerifyDetailsContent').hide();
                $('.VerifiedDetailsContent').show();
            }

        })
        /*END OF DOCUMENT READY*/

    },
});