import Handlebars from "handlebars/dist/handlebars.js";
import Cookies from "js-cookie";

$.widget("custom.highlightapp", {
    options: {
        android: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $placeholder = $(".container-fluid", $element),
            template = Handlebars.compile($("script", $element).html()),
            isIOs = /CriOS/ig.test(navigator.userAgent),
            isAndroid = /Android/ig.test(navigator.userAgent),
            $navbar = $("nav.navbar");

        function touchMoveScroll() {
            var scrollTop = 110 - $(document).scrollTop();
            if (scrollTop < 35) scrollTop = 35;
            $navbar.stop().animate({ top: scrollTop }, 10);
        }

        function removeAllClick() {
            $(window).off("touchmove", touchMoveScroll).off("scroll", touchMoveScroll);
            Cookies.set("HighLightApp", true, { path: "/", expires: 365 });
            $element.remove();
            $navbar.removeClass("navbar-fixed-top-spelpaus-highlight navbar-fixed-top-highlight").addClass("navbar-fixed-top-spelpaus");
        }

        function rounding(number, precision, rounding) {
            var multiply = 1 / rounding;
            return parseFloat((Math.round(number * multiply) / multiply)).toFixed(precision);
        }

        if (isIOs || isAndroid) {
            $.ajax({
                url: "https://itunes.apple.com/se/lookup?id=577416892",
                dataType: "jsonp",
                method: "GET",
                success: function(data) {
                    if (data.resultCount !== 1) {
                        return;
                    }

                    var averageUserRatingForCurrentVersion = data.results[0].averageUserRatingForCurrentVersion,
                        averageUserRatingIcon = rounding(averageUserRatingForCurrentVersion, 1, 0.5);
                    if (averageUserRatingForCurrentVersion % 1 === 0) {
                        averageUserRatingIcon = averageUserRatingForCurrentVersion.toString() + ".0";
                    }

                    var content = {
                        icon: data.results[0].artworkUrl60,
                        name: data.results[0].trackCensoredName,
                        publisher: data.results[0].artistName,
                        link: data.results[0].trackViewUrl,
                        ratingIcon: averageUserRatingIcon.replace(".", "p"),
                        rating: averageUserRatingForCurrentVersion
                    };

                    if (isAndroid) {
                        content.link = $widget.options.android;
                    };

                    $placeholder.empty().append(template(content));

                    $("button", $placeholder).click(function(e) {
                        e.preventDefault();
                        removeAllClick();
                    });

                    $("a.open", $placeholder).click(removeAllClick);

                    $(window).on("touchmove", touchMoveScroll).on("scroll", touchMoveScroll);
                }
            });
        }
    }
});