$.widget("custom.nativehistoryavailable", {
    options: {},
    _create: function () {
        var $widget = this,
            $element = $widget.element;
			
        if(!$element.is(":visible")) {
            return;
        }

        $element.on("click", function(e) {
            e.preventDefault();

            if (/Ip(ad|od|hone)/ig.test(navigator.userAgent) && window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    type: "history.back",
                    payload: null
                }));
            } else {
                window.history.back();
            }

            if ($("#sidebarNavigation").is(":visible"))
                $("#sidebarNavigation").offcanvas("hide");

            if ($("#sidebarMypages").is(":visible"))
                $("#sidebarMypages").offcanvas("hide");

            return false;
        });
    }
});