$.widget("custom.optout", {
  options: {
    geturl: ""
  },
  _create: function() {
    var $widget = this,
      $element = $widget.element,
      $totaloptout = $(".distributions .totaloptout input[type='checkbox']", $element),
      $containers = $(".distributions", $element),
      $totalchannels = $(".distributions .istotal input[type='checkbox']:checked", $element),
      $checkboxes = $(".distributions div[data-istotal] input[type='checkbox']", $element);

    $totaloptout.on("change", function() {
      var $input = $(this),
        isChecked = $input.is(":checked");
      if (isChecked) {
        $checkboxes.prop("disabled", true).attr("disabled", "disabled").addClass("disabled").prop("checked", false).removeAttr("checked");
      } else {
        $checkboxes.prop("disabled", false).removeAttr("disabled").removeClass("disabled");
      }
    });

    $checkboxes.on("change", function() {
      var $input = $(this),
        channelCode = $input.parent().data("channelcode"),
        isTotal = $input.parent().data("istotal"),
        isChecked = $input.is(":checked"),
        channelBoxes = $("[data-channelcode='" + channelCode + "'][data-istotal='false'] input[type='checkbox']", $containers);

      if (isChecked && isTotal) {
        channelBoxes.prop("disabled", true).attr("disabled", "disabled").addClass("disabled").prop("checked", false).removeAttr("checked");
      } else if (!isChecked && isTotal) {
        channelBoxes.prop("disabled", false).removeAttr("disabled").removeClass("disabled");
      }
    });

    $checkboxes.prop("disabled", false).removeAttr("disabled").removeClass("disabled");
    if ($totaloptout.is(":checked")) {
      $checkboxes.prop("disabled", true).attr("disabled", "disabled").addClass("disabled").prop("checked", false).removeAttr("checked");
    } else {
      $totalchannels.parent().siblings().find("input[type='checkbox']").prop("disabled", true).attr("disabled", "disabled").addClass("disabled");
    }
  }
});