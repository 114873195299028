$.widget("custom.gamehtml5", {
    options: {
        gameid: null,
        session: null,
        windowwidth: $(window).width(),
        windowheight: $(window).height()
    },
    _create: function () {
        var $widget = this;
        
        if (!$widget.options.session && $("body").data("sessionid")) {
            $widget.options.session = $("body").data("sessionid");
        }
        $widget._loadClientScript();

        $(window).on('resize', function () {
            $widget._windowSizeChanged();
        });
        $(window).on("orientationchange", function () {
            $widget._windowSizeChanged();
        });
    },
    _windowSizeChanged: function () {
        var $widget = this;
        if ($widget.options.timeout) {
            clearTimeout($widget.options.timeout);
        }
        $widget.options.timeout = setTimeout(function () {
            if ($widget.options.windowwidth != $(window).width() || 
                $widget.options.windowheight != $(window).height()) {
                window.location.reload();
            }
        }, 500);
    },
    _loadClient: function () {
        var $widget = this;
        $widget.options.gamecontainer = $("<div class=\"gamecontainer col-sm-12 block\" style='padding:0;background-color:red;'></div>").appendTo($widget.element);
        $widget._resize();
        var nyxclient = new NyxClient($widget.options.gamecontainer.get(0));
        window['nyxclient'] = nyxclient;
        nyxclient.Run({
            messageSequences: MessageSequences,
            skinname: null,
            language: null,
            culture: null,
            operator: null,
            partner: null,
            gameId: $widget.options.gameid,
            streamId: null,
            session: $widget.options.session,
            chatEnabled: null,
            debug: false,
            handle: null,
            userId: null,
            wagerId: null,
            nyxroot: null,
            skipFastForward: true,
            overrideWithLocalStorage: true
        });
       
    },
    _loadClientScript: function () {
        var $widget = this,
            gameclientscriptpath = '/nyxclient/bingo/nyxclient.min.js',
            head = document.getElementsByTagName('head')[0],
            script = document.createElement('script');
        script.src = gameclientscriptpath;
        var callback = function () {
            $widget._loadClient();
        };
        script.onreadystatechange = callback;
        script.onload = callback;
        head.appendChild(script);
    },
    _resize: function () {
        var $widget = this,
            $window = $(window),
            width = $widget.element.width() > 0 ? $widget.element.width() : $window.width(),
            height = $window.height() >= $window.width() ? width + 100 : width * 0.67;
        //alert("width: " + width + " height: " + height);
        $widget.options.gamecontainer.css("height", height + 'px').css("width", width + 'px');
    }
});