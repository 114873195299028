$.widget("custom.activateticket", {
    options: {
        monitor: "",
        email: "",
        mobile: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $monitors = $("input[name='".concat($widget.options.monitor, "']"), $element),
            $email = $("input[id^='".concat($widget.options.email, "']"), $element),
            $mobile = $("#".concat($widget.options.mobile), $element);

        function radioChanged(value) {
            if (/sms/ig.test(value)) {
                $email.val("").hide();
                $mobile.show();
            } else {
                $email.show();
                $mobile.val("").hide();
            }
        }

        $monitors.change(function () {
            var $this = $(this);
            if (!$this.prop("checked")) return;
            radioChanged($this.val());
        });

        radioChanged($($monitors.selector.concat(":checked"), $element).val());
    }
});