$.widget("custom.blogposts", {
    options: {
        contentguid: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $container = $element.closest("div.col-xs-12.col-sm-12.col-md-10");

        $element.off("click").click(function (e) {
            e.preventDefault();
            $element.attr("disabled", "disabled");

            var year = Utils.GetUrlParamByName("year");
            var month = Utils.GetUrlParamByName("month");
            var tag = Utils.GetUrlParamByName("tag");
            var author = Utils.GetUrlParamByName("author");

            if (!/\d+/ig.test(year)) year = null;
            if (!/\d+/ig.test(month)) month = null;

            var currentPage = $("div[data-currentpage]", $container).last().data("currentpage");
            if (typeof currentPage !== "number") currentPage = -1;

            $.ajax({
                url: "/api/articlefolderajax/index",
                method: "GET",
                data: {
                    contentguid: $widget.options.contentguid,
                    page: currentPage + 1,
                    year: year,
                    month: month,
                    tag: tag,
                    author: author
                },
                dataType: "html",
                success: function (data) {
                    var $data = $(data), pages = $data.data("pages");
                    $data.insertBefore($element);
                    if (pages <= 0) $element.remove();
                },
                complete: function () {
                    $element.removeAttr("disabled");
                }
            });
        });
    }
});