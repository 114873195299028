$.widget("custom.subscriptionticketsform", {
    options: {
        checkbox: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $singleTickets = $("select[id$='SingleTickets']", $form),
            $doubleTickets = $("select[id$='DoubleTickets']", $form).prop("disabled", true).attr("disabled", "disabled"),
            $submit = $("button[type='submit']", $form).prop("disabled", true).attr("disabled", "disabled"),
            singleticketsvalid = false,
            checkboxvalid = true;


        if ($widget.options.checkbox !== "") {
            checkboxvalid = $("#" + $widget.options.checkbox, $form)
                .off("change", checkboxChange)
                .on("change", checkboxChange)
                .prop("checked");
        }

        function checkboxChange() {
            checkboxvalid = $(this).prop("checked");
            if (checkboxvalid && singleticketsvalid) {
                $submit.prop("disabled", false).removeAttr("disabled");
            } else {
                $submit.prop("disabled", true).attr("disabled", "disabled");
            }
        }

        function checkSingle($element) {
            singleticketsvalid = $element.val() !== "" && $element.val() !== "0";
            if (singleticketsvalid) {
                $doubleTickets.prop("disabled", false).removeAttr("disabled");

                if (checkboxvalid) {
                    $submit.prop("disabled", false).removeAttr("disabled");
                } else {
                    $submit.prop("disabled", true).attr("disabled", "disabled");
                }
            } else {
                $doubleTickets.prop("disabled", true).attr("disabled", "disabled").val("0");
                $submit.prop("disabled", true).attr("disabled", "disabled");
            }
        }

        checkSingle($singleTickets);

        $singleTickets.on("keypress change", function (e) {
            var keyCode = (e.keyCode || e.which);
            if (keyCode === 13 && checkboxvalid && singleticketsvalid) $submit.click();

            checkSingle($(this));
        });


        if ($doubleTickets.length === 1) {
            $doubleTickets.on("keypress change", function (e) {
                var keyCode = (e.keyCode || e.which);
                if (keyCode === 13 && checkboxvalid && singleticketsvalid) $submit.click();
            });
        }
    }
});