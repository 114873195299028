$.widget("custom.disablesubmit", {
    options: {
        checkbox: "",
        modalcloseevent: ""
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $modalcontent = $(".modalcontent", $form),
            hasAccepted = false;

        if ($widget.options.checkbox === "") return;

        var $checkbox = $("#" + $widget.options.checkbox, $form);
        var $submit = $("button[type='submit']", $form).prop("disabled", true).attr("disabled", "disabled");

        function checkboxChange() {
            if ($checkbox.prop("checked")) {
                $submit.prop("disabled", false).removeAttr("disabled");
            } else {
                $submit.prop("disabled", true).attr("disabled", "disabled");
            }
        }

        $checkbox.off("change", checkboxChange).on("change", checkboxChange);
        checkboxChange();

        $form.submit(function(e) {
            if ($modalcontent.length === 1 && $modalcontent.html() !== "" && $form.valid() && !hasAccepted) {
                e.preventDefault();
                ModalHandler.OpenModal({
                    modalShown: function($modal) {
                        var $body = $("<div />").addClass("modal-body")
                            .html("<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Avbryt\"><i class=\"far fa-times-circle fa-lg\"></i></button>" +
                                $modalcontent.html() +
                                "<button type=\"button\" class=\"btn btn-warn\" data-dismiss=\"modal\" aria-label=\"Avbryt\">Avbryt</button> " +
                                "<button type=\"button\" class=\"btn btn-success\" data-dismiss=\"modal\" aria-label=\"Stäng\">Jag förstår</button>");
                        $(".modal-content", $modal).append($body);

                        if ($widget.options.modalcloseevent !== "") {
                            $("[data-dismiss]:not(.btn-success)").click(function () {
                                if (typeof (dataLayer) !== "undefined") {
                                    dataLayer.push({ "event": "virtualPageView", "virtualPage": $widget.options.modalcloseevent });
                                } else if (typeof (ga) !== "undefined") {
                                    ga("send", "pageview", $widget.options.modalcloseevent);
                                }
                            });
                        }

                        $(".btn-success", $modal).click(function (b) {
                            hasAccepted = true;
                            $form.submit();
                        });
                    }
                });
                return;
            }
        });
    }
});