$.widget("custom.lotteryticketsteaserform", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $roundDropDown = $("#Round", $element),
            $roundPanels = $(".roundPanel", $element),
            $dropdowns = $("select", $roundPanels),
            $buyButton = $(".btn", $element);

        function toggleRoundPanels() {
            $roundPanels.hide();
            
            $dropdowns.each(function () {
                $(this).val("0").change();
                $(this).trigger("statechanged");
            });
                
            $("#r_" + $roundDropDown.val()).fadeIn();
        }

        $roundDropDown.change(toggleRoundPanels);

        $buyButton.click(function (e) {
            e.preventDefault();
            var url = $element.attr("action");
            var param = "";
            $dropdowns.each(function () {
                var $this = $(this);
                if ($this.val() !== null && $this.val() !== '' && $this.val() !== '0') {
                    param += $this.attr("id").replace("RoundArticle_", "") + ":" + $this.val() + ";";
                }
            });
            if (param === "") {
                var $firstVisiblePanel = $roundPanels.filter(":visible").eq(0);
                var $firstEnabledDropDown = $firstVisiblePanel.find("select").filter(":not([disabled])").eq(0);
                if ($firstEnabledDropDown.length > 0) {
                    param += $firstEnabledDropDown.attr("id").replace("RoundArticle_", "") + ":1;";
                }
            }

            if (param !== "") {
                url = url + "?preSelect=" + param;
            }

            window.location = url;
        });
    }
});