import "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "bootstrap-sass";
import "jasny-bootstrap/dist/js/jasny-bootstrap.js";
import "execute_method/dist/execute_method.js";
import "jquery-csswatch/dist/jquery-csswatch.js";
import "devbridge-autocomplete";
import "../../vendors/jquery-validate/jquery.validate.overrides.js";
import "../../vendors/jquery-validate/jquery.validate.custom.js";
import "../../vendors/select/js/classie.js";
import "../../vendors/select/js/selectFx.js";
import "../../vendors/swfobject/swfobject.js";
import "../defaultoptions.js";
import "../events";
import "../storage";
import "../urls.js";
import "../utils.js";
import "../jquery.bankid.js";
import "../jquery.extensions.js";
import "../jquery.safeform.js";
import "../jquery.placeholder.js";
import "../jquery.equalheight.js";
import "../jquery.newsTicker.min.js";
import "../kontonummer.js";
import "../modalhandler.js";
import "../ajaxhandler.js";
import "../widgethandler.js";
import "../formhandler.js";
import "../tabhandler.js";
import "../validationhandler.js";
import "../jquery.countdown.min.js";
import "../functions.js";
import "../functions_bingolotto.js";

import "../../vendors/slick/slick.js";

import "../tickets";
import "../search";
import "../typeform";
import "../firstpurchase";
import "./carousel";


var common = require.context("../widgets/common/", true, /\.js$/);
common.keys().forEach(common);

var bingolotto = require.context("../widgets/bingolotto", true, /\.js$/);
bingolotto.keys().forEach(bingolotto);