import Handlebars from "handlebars/dist/handlebars.js";
import { Spinner } from "spin.js";
import moment from "moment";

$.widget("custom.winnerticker", {
    options: {
        interval: null
    },
    _create: function () {
        var $widget = this,
            $form = $widget.element,
            $container = $("ul", $form),
            template = Handlebars.compile($(".winnertemplate", $form).html()),
            spinner = new Spinner(DefaultOptions.Spinner),
            ticker = $container.newsTicker({
                row_height: 40,
                max_rows: 1,
                duration: 10000,
                direction: "down",
                autostart: 0
            });

        $form.parent().hide();

        Handlebars.registerHelper("format-date", function (date, options) {
            return moment(date).fromNow();
        });

        Handlebars.registerHelper("format-amount", function(amount, options) {
            if (!amount) return "";
            if (amount >= 1000000) return " amount-large";
            if (amount >= 100000) return " amount-medium";
            if (amount >= 10000) return " amount-small";
            return "";
        });

        var populateWinners = function () {
            ticker.newsTicker("stop");
            spinner.spin($form.get(0));

            $.getJSON(Urls.WinnerTicker, { _: new Date().getTime() }, function(data) {
                if (data.length === 0) {
                    $form.parent().hide();
                } else {
                    $form.parent().show();
                    $container.fadeOut().html(template(data)).fadeIn(500);
                    ticker.newsTicker("start");
                }
                spinner.stop();
            });
        };
        setTimeout(function () {
            populateWinners();
        }, 600);
        $widget.options.interval = setInterval(populateWinners, 2 * 60 * 1000);
    },
    _destroy: function () {
        var $widget = this;
        if ($widget.options.interval)
            clearInterval($widget.options.interval);
    }
});