$.widget("custom.calendarcheckerform", {
    options: {},
    _create: function () {
        var $widget = this,
          $form = $widget.element,
          $lotteryNumber = $("input[id$='LotteryNumber']", $form),
          $submit = $("button[type='submit']", $form).prop("disabled", true).attr("disabled", "disabled"),
          validKeyCodes = [8, 13, 37, 38, 39, 40, 46];

        function checkLottery($element, e, keyCode) {
            var trailingValue = $element.val();
            if (trailingValue.length === parseInt($element.attr("maxlength"))) {
                $submit.prop("disabled", false).removeAttr("disabled");
                if (typeof (e) !== "undefined" && e != null && validKeyCodes.indexOf(keyCode) === -1) e.preventDefault(); // causes issues with firefox
                if (typeof (keyCode) === "number" && keyCode === 13) $submit.click();
            } else {
                $submit.prop("disabled", true).attr("disabled", "disabled");
            }
        }

        checkLottery($lotteryNumber, null, 0);

        $lotteryNumber.on("keyup keypress", function (e) {
            var keyCode = (e.keyCode || e.which);
            checkLottery($(this), e, keyCode);
        });
    }
});