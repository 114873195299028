$.widget("custom.jackpotsubscription", {
    options: {},
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        $("input[type='number']", $element).change(function() {
            $("i.fas.fa-check-circle", $element).remove();
            if ($(this).val().length === 0) return;
            $(".block-subscribe", $element).append($("<i />").addClass("fas fa-check-circle"));
        });
    }
});