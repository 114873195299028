$.widget("custom.buylotteryticketsformstep3", {
    options: {
        firstpurchasecategory: "",
        succeeded: false
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element;

        if ($widget.options.succeeded) {
            if ($widget.options.firstpurchasecategory) {
                window.fsFirstPurchase.handleFirstPurchase($widget.options.firstpurchasecategory);
            }
        }
    },
    
});