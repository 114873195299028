$.widget("custom.wizardsteps", {
    options: {
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $form = $("form", $element);

        $element.on("AfterAjaxFormReplaced", "form", function () { $widget._syncWizardSteps($(this)); });

        this._syncWizardSteps($form);
    },
    _syncWizardSteps: function ($form) {
        var widget = this,
            $wizardnav = $(".wizard-navigation", widget.element);
        var step = $form.data("wizardstep");
        if (step && $wizardnav) {
            var $navStep = $("[data-wizardstep='" + step + "']", $wizardnav);
            if ($navStep.length === 1) {
                var $prevStep = $navStep.prev("li");
                $("li", $wizardnav).removeClass("active");
                $navStep.addClass("active");
                if ($prevStep.length === 1) {
                    var html = $prevStep.html().replace(/<span>\d*<\/span>/, "<span><i class=\"far fa-check-circle\"></i></span>");
                    $prevStep.addClass("completed").html(html);
                }
            }
        }
    }
});