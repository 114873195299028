$.widget("custom.avatarselect", {
  options: {
  },
  _create: function () {
    var $widget = this,
        $form = $widget.element,
        $images = $("img", $form);

    $images.on("click", function () {
      $images.removeClass("active");
      $(this).addClass("active");
    });
  }
});