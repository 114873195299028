$.widget("custom.campaignform", {
  _create: function () {
    if (typeof ($$epiforms) !== "undefined") {
      $$epiforms(document).ready(function() {
        $$epiforms(".EPiServerForms").on("formsSubmitted", function (event) {
          location.href = Utils.AddUrlParam(location.href.split("#")[0], "s", "2");
        });
      });
    }
  }
});