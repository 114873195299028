$.widget("custom.contactform", {
  options: {
  },
  _create: function () {
    var $widget = this,
        $form = $widget.element,
        socialSecurityNumber = "", customerNumber = "", invoiceNumber = "";

    function selectChange() {
      var datatarget = $("option:selected", $(this)).data("target");

      $("div.topic-lead", $form).hide();
      // save temp value and hide group
      $("div.topic-input", $form).each(function () {
        var $group = $(this);
        if ($group.css("display") === "block") {
          socialSecurityNumber = $("#SocialSecurityNumber", $group).val();
          customerNumber = $("#CustomerNumber", $group).val();
          invoiceNumber = $("#InvoiceNumber", $group).val();
        }
        $group.hide();
      });

      // empty and disable inputs
      $("div.topic-input input", $form).val("").prop("disabled", true).attr("disabled", "disabled");

      if (datatarget === "") return;
      var $lead = $("div.topic-lead.a".concat(datatarget), $form);
      var $input = $("div.topic-input.a".concat(datatarget), $form);

      // put temp value and show group
      $("input", $input).each(function () {
        var $self = $(this).prop("disabled", false).removeAttr("disabled");
        switch ($self.attr("id")) {
          case "SocialSecurityNumber":
            $self.val(socialSecurityNumber);
            break;

          case "CustomerNumber":
            $self.val(customerNumber);
            break;

          case "InvoiceNumber":
            $self.val(invoiceNumber);
            break;
        }
      });

      $lead.show(300);
      $input.show(300);
    }

    $("select", $form).off("change", selectChange).on("change", selectChange);
  }
});