$(document).ready(function () {
    $('.slider').each(function(){
        $(this).slick({
            arrows: false,
            lazyLoad: 'ondemand',
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 2000,
            slidesToShow: 7,
            slidesToScroll: 1,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3
                }
            }]
        })
    });
});






