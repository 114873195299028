$.widget("custom.buylotteryticketsformstep1", {
    options: {
        singleticketroundoccasions: "",
        doubleticketroundoccasions: "",
        specialticketroundoccasions: "",
        ticketimagenamesuffix: ""
    },
    _create: function () {
        var $widget = this,
            $element = $widget.element,
            $roundDropDown = $("select.dd-round", $element),
            $roundPanels = $(".roundPanel", $element),
            /*$dropdowns = $("select", $roundPanels),*/
            $buyButton = $(".btn", $element),
            $decrementButton = $(".decrement-btn", $element),
            $incrementButton = $(".increment-btn", $element),
            $quantityInput = $(".quantity-input", $element),
            $sum = $("#sum", $element);

        $(document).ready(function () {

            if ($quantityInput && $roundPanels) {
                const currentURL = window.location.href;
                if (!currentURL.includes("sk=") && !currentURL.includes("s=")) {
                populateQuantity();
                }
            }
            $('.quantity-input').each(function () {
                if ($(this).val() !== "0") {
                    toggleDisabled($(this));
                }
            });
        });

        function changeQuantity(button, value) {
            var input = $(button).siblings('.quantity-input');
            var currentVal = parseInt(input.val());
            currentVal = isNaN(currentVal) ? 0 : currentVal;
            if (currentVal === 0 && value < 0) {
                return;
            };
            var newVal = currentVal + value;
            input.val(newVal.toString());
            toggleDisabled(input);
            updateSum();
        };

        function toggleDisabled(input) {
            var decbutton = $(input).siblings(".decrement-btn");
            var incbutton = $(input).siblings(".increment-btn");
            var value = $(input).val();

            if (value !== "0") {
                decbutton.prop("disabled", false);
            } else {
                decbutton.prop("disabled", true);
            };

            if (value >= 10) {
                incbutton.prop("disabled", true);
            } else {
                incbutton.prop("disabled", false);
            };
        };

        function updateSum() {
            var sum = 0;
            $quantityInput.each(function () {
                var quantity = parseInt($(this).val());
                var price = parseFloat($(this).attr("data-price"));
                if (!isNaN(quantity) && !isNaN(price)) {
                    sum += quantity * price;
                };
            });
            $sum.text(sum + " kr");
        };

        function populateQuantity() {
            $roundPanels.each(function () {
                var roundPanel = $(this);
                var valueToMatch = $roundDropDown.val();
                if (roundPanel.attr("id") == "r_" + valueToMatch || roundPanel.attr("id") == "ri_" + valueToMatch) {
                    var input = roundPanel.find("input").filter(":not([disabled])").last();
                    input.val("1");
                    toggleDisabled(input);
                    updateSum();
                };
            });
        };

        function resetQuantity() {
            $quantityInput.each(function () {
                if ($(this).val !== "0") {
                    $(this).val("0")
                    updateSum();
                    toggleDisabled($(this));
                };
            });
            updateSum();
            populateQuantity();
        };

        function toggleRoundPanels() {
            $roundPanels.hide();
            //$dropdowns.each(function () { 
            //    $(this).val("0").change();
            //    $(this).trigger("statechanged");
            //});
            $("#r_" + $roundDropDown.val()).fadeIn();
            $("#ri_" + $roundDropDown.val()).fadeIn();
            resetQuantity();
        };

        $buyButton.click(function () {
            var anySelected = false;
            $quantityInput.each(function () {
                var $this = $(this);
                if ($this.val() !== null && $this.val() !== '' && $this.val() !== '0' && $this.val() !== 0) {
                    anySelected = true;
                };
            });
            if (!anySelected) {
                //var $lastEnabledInput = $roundPanels.find("input").filter(":not([disabled])").last();
                //$lastEnabledInput.val("1");
                populateQuantity();
            };
        });

        $decrementButton.click(function () {
            changeQuantity(this, -1);
        });

        $incrementButton.click(function () {
            changeQuantity(this, 1);
        });

        $quantityInput.on("input", function () {
            updateSum();
        });

        $("#selectRoundLink").click(function (e) {
            e.preventDefault();
            $("#selectRoundLabel").hide();
            $("#selectRound").show();
        });

        $roundDropDown.change(toggleRoundPanels);

    }
});