$.widget("custom.openinnewtab", {
    options: {},
    _create: function() {
        var $widget = this,
            $element = $widget.element,
            $select = $("select", $element);

        function selectChange(e) {
            var value = $("option:selected", $(this)).val();
            if (value === "" || e.bubbles) return false;
            window.open(value, "_blank");
            return false;
        }

        if ($select.length === 0) return;
        $select.off("change", selectChange).on("change", selectChange);
    }
});